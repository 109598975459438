import workspace, { name as workspaceName } from '../../../core/workspace';
import { createApp } from './app';

const { app } = createApp({
  locale: workspace.$locale,
  urls: {
    cart: workspace.$urls.cart_summary,
  },
});

global[workspaceName].$cartsummary = app.$mount();
