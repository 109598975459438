import workspace from './workspace';
import { createApp as createCartSummaryApp } from '../vue/cart/summary/app';
import $simpleClient from '../common/rest/simpleClient';

const prepareWorkspace = () => {
  // Create the vue app & mount it
  const cartApp = createCartSummaryApp({
    locale: workspace.$locale,
    urls: {
      cart: workspace.$urls.cart_summary,
    },
  }).app;

  workspace.$cartsummary = cartApp.$mount();

  // Create the prepared modal with the vue app inside
  const modal = workspace.$modalFactory({
    stickyFooter: false,
    closeMethods: ['escape'],
  });

  modal.setContent(cartApp.$el);
  workspace.$cartSummaryModal = modal;

  // Handle the main menu go back button
  workspace.$bus.$on('cartSummaryGoBack', () => {
    modal.close();
  });
};

const onDOMLoaded = () => {
  document.querySelector('.topbar__cartbutton').addEventListener('click', (event) => {
    event.preventDefault();

    workspace.$bus.$emit('cartRefreshing', true);

    $simpleClient
      .get(workspace.$urls.cart_as_json)
      .then(cart => workspace.$bus.$emit('cartRefreshed', cart));
    workspace.$cartSummaryModal.open();
  });
};

export {
  prepareWorkspace,
  onDOMLoaded,
};
