import Vue from 'vue';
import VueMq from 'vue-mq';
import App from './App.vue';

Vue.use(VueMq, {
  breakpoints: {
    mobile: 640,
    laptop: Infinity,
  },
});

export function createApp(options) {
  const app = new Vue({
    render(h) {
      return h(App, {
        props: {
          version: options.version,
        },
      });
    },
  });

  return { app };
}
