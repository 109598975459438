<template>
    <div>
        <div class="modal modal--addtocart">
            <div class="modal__header">
                <div class="modal__title">vous avez ajouté</div>
                <a class="modal__back" v-on:click="goBack"><span>Retour</span></a>
            </div>
            <div class="modal__main">
                <div class="addtocart">
                    <div class="addtocart__image">
                        <img v-if="item.product.image" :src="item.product.image.portrait_small" :alt="item.product.name">
                    </div>
                    <div class="addtocart__content">
                        <div class="addtocart__productname">
                            <span>{{ item.product.name }}</span><br>
                            <span v-if="item.variant.options && item.variant.options.length">
                                {{ itemVariantOptions }}
                            </span>
                        </div>
                        <div class="addtocart__quantity">Quantité : <span>{{ item.quantity }}</span></div>
                        <div class="addtocart__price">Prix : <span>{{ item.total.formatted }}</span></div>
                    </div>
                </div>
            </div>
            <div class="modal__w-aside">
                <div class="modal__aside">
                    <totals :totals="cart.totals"></totals>
                    <a :href="urls.cart" class="modal__button">Commander</a>
                    <a class="modal__button modal__button--back" v-on:click="goBack">Continuer mon shopping</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Totals from '../../summary/components/Totals.vue';

export default {
    name: 'addToCartSuccess',
    components: {
        Totals,
    },
    props: [
        'cart',
        'item',
        'urls',
    ],
    computed: {
        itemVariantOptions () {
            return this.item.variant.options.join(', ');
        },
    },
    methods: {
        goBack: function () {
            this.$emit('goBack');
        },
    },
};
</script>