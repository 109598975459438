import workspace from './workspace';
import { createApp as createMenuApp } from '../vue/menu/app';

const prepareWorkspace = () => {
  // Create the vue app & mount it
  const menuApp = createMenuApp({
    version: workspace.$mainmenuversion,
  }).app;

  workspace.$mainmenu = menuApp.$mount();

  // Create the prepared modal with the vue app inside
  const modal = workspace.$modalFactory({
    stickyFooter: false,
    closeMethods: ['escape'],
    cssClass: ['tingle-modal--menu'],
  });
  modal.setContent(menuApp.$el);
  workspace.$mainmenuModal = modal;

  // Handle the main menu go back button
  workspace.$bus.$on('mainMenuGoBack', () => {
    modal.close();
  });

  // Handle the main menu modal overflow
  workspace.$bus.$on('mainMenuDomUpdated', () => {
    modal.checkOverflow();
  });
};

const onDOMLoaded = () => {
  // Laptop
  const laptopItems = document.querySelectorAll('.menumain__item[data-menu-modal="1"]');

  const openLaptopItem = (event) => {
    event.preventDefault();

    const item = event.target;
    workspace.$bus.$emit('selectMainMenuItem', item.dataset.menuItem);
    workspace.$mainmenuModal.open();
  };

  Array.from(laptopItems).map((element) => {
    element.addEventListener('click', openLaptopItem);
  });

  // Mobile
  document.querySelector('.topbar__togglemenu').addEventListener('click', () => {
    workspace.$mainmenuModal.open();
  });
};

export {
  prepareWorkspace,
  onDOMLoaded,
};
