import 'owl.carousel';

$(document).ready(() => {
  $('.owl-carousel').owlCarousel({
    loop: true,
    autoplay: true,
    responsiveClass: true,
    responsive: {
      0: {
        items: 2,
        nav: false,
      },
      480: {
        items: 3,
        nav: false,
      },
      720: {
        items: 4,
        nav: false,
      },
      960: {
        items: 5,
        nav: true,
      },
    },
  });
});
