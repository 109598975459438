<template>
    <ul :class="className">
        <li v-for="item in items" :key="item.name">
            <a :href="item.uri">{{ item.label }}</a>
            <laptop-sub-menu-items :items="item.items" v-if="item.items && item.items.length" :level="item.level"></laptop-sub-menu-items>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'laptop-sub-menu-items',
    props: {
        items: {
            type: Array,
            required: true,
        },
        level: {
            type: Number,
            required: true,
        },
    },
    computed: {
        className () {
            return `submenu__level${this.level}`;
        },
    },
}
</script>