var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.items, function (item) {
      return _c(
        "a",
        {
          key: item.name,
          staticClass: "menumain__item",
          class: { "menumain__item--active": _vm.selected === item.name },
          attrs: { href: item.uri, "data-menu-item": item.name },
          on: {
            click: function ($event) {
              return _vm.select($event, item)
            },
          },
        },
        [_vm._v(_vm._s(item.label))]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }