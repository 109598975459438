<template>
    <ul class="menumobile">
        <li v-for="item in items" :key="item.name">
            <a
                :href="item.uri"
                :class="{ 'menumobile__item': hasItems(item), 'childfree': !hasItems(item), active: selected === item.name }"
            >{{ item.label }} <span v-on:click="select($event, item)"></span></a>
            <ul v-if="isOpen(item)" :class="{ active: selected === item.name }">
                <mobile-menu-item v-for="subitem in item.items" :key="subitem.name" :item="subitem"></mobile-menu-item>
            </ul>
        </li>
    </ul>
</template>

<script>
import MobileMenuItem from "./MobileMenuItem.vue";
import itemMixin from '../mixin/item';

export default {
    components: {
        MobileMenuItem
    },
    name: 'mobile-menu',
    props: {
        items: {
            type: Array,
            required: true,
        },
        selected: {
            type: String,
            required: false,
        },
    },
    mixins: [ itemMixin ],
    methods: {
        select: function (event, item) {
            if (this.hasItems(item)) {
                event.preventDefault();
                this.$emit('selectMainItem', this.selected !== item.name ? item.name : null);
            }
        },
        isOpen: function (item) {
            return this.selected === item.name && this.hasItems(item);
        },
    },
}
</script>
