import axios from 'axios';

/**
 * @param baseURL
 */
const factory = baseURL => axios.create({ baseURL });

/**
 * @param response
 */
const extractDataFromResponse = response => response.data;

export default factory;
export { extractDataFromResponse };
