<template>
    <div class="cartsummary">
        <div class="cartsummary__item">
            <div class="cartsummary__itemlabel">Total produits TTC</div>
            <div class="cartsummary__itemvalue">{{ totals.items_no_discount.formatted }}</div>
        </div>
        <div class="cartsummary__item" v-if="totals.promotion_total.raw < 0">
            <div class="cartsummary__itemlabel">Réduction</div>
            <div class="cartsummary__itemvalue">{{ totals.promotion_total.formatted }}</div>
        </div>
        <div class="cartsummary__item">
            <div class="cartsummary__itemlabel">Livraison</div>
            <div class="cartsummary__itemvalue">{{ totals.shipping.formatted }}</div>
        </div>
        <div class="cartsummary__item">
            <div class="cartsummary__itemlabel">Total TVA</div>
            <div class="cartsummary__itemvalue">{{ totals.tax.formatted }}</div>
        </div>
        <div class="cartsummary__item">
            <div class="cartsummary__itemlabel">Total TTC</div>
            <div class="cartsummary__itemvalue">{{ totals.total.formatted }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'totals',
    props: {
        totals: {
            type: Array,
            required: true,
        },
    },
};
</script>