<template>
    <div>
        <notification ref="notification" v-if="notification" :notification="notification" v-on:close="clearNotification(notification)"></notification>
    </div>
</template>

<script>
import Bus from '../common/bus/Bus';
import { ShopFactory as $modalFactory} from '../../common/modal';
import Notification from './components/Notification.vue';

export default {
    name: 'app',
    props: {},
    components: {
        Notification,
    },
    data: function () {
        return {
            notification: null,
            notifications: [],
        };
    },
    created () {
        this.$modal = new $modalFactory({
            cssClass: ["tingle-modal--notification"],
            onClose: () => {
                this.notification = null;
                this.notifications.splice(0, this.notifications.length);
            },
        });

        Bus.$on('dispatchNotification', (notification) => {
            this.notifications.push(notification);
            this.notification = notification;

            this.$nextTick(() => {
                this.$modal.setContent(this.$refs.notification.$el);

                if (!this.$modal.isOpen()) {
                    this.$modal.open();
                }
            });
        });
    },
    methods: {
        clearNotification: function (notification) {
            const index = this.notifications.indexOf(notification);

            if (index !== -1) {
                this.notifications.splice(index, 1);
            }

            if (this.notifications.length) {
                this.notification = this.notifications.pop();
            } else {
                this.notification = null;
                this.$modal.close();
            }
        },
    },
};
</script>