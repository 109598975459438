var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal modal--cartsummary" }, [
    _c("div", { staticClass: "modal__header" }, [
      _c("div", { staticClass: "modal__title" }, [_vm._v("Votre panier")]),
      _vm._v(" "),
      _c("div", { staticClass: "modal__back", on: { click: _vm.goBack } }, [
        _c("span", [_vm._v("Retour")]),
      ]),
    ]),
    _vm._v(" "),
    !_vm.pending && _vm.cart.items.length == 0
      ? _c("div", { staticClass: "modal__main" }, [
          _c("p", [_vm._v("\n            Votre panier est vide\n        ")]),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.pending && _vm.cart.items.length
      ? _c(
          "div",
          { staticClass: "modal__main" },
          [_c("items", { attrs: { items: _vm.cart.items } })],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.pending && _vm.cart.items.length
      ? _c("div", { staticClass: "modal__w-aside" }, [
          _c("div", { staticClass: "modal__aside modal__aside--sticky" }, [
            _c(
              "div",
              { staticClass: "modal__inneraside" },
              [
                _c("totals", { attrs: { totals: _vm.cart.totals } }),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "modal__button",
                    attrs: { href: _vm.urls.cart },
                  },
                  [_vm._v("Commander")]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "modal__button modal__button--back",
                    attrs: { href: _vm.urls.cart },
                  },
                  [_vm._v("Modifier mon panier")]
                ),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }