var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { class: _vm.className },
    _vm._l(_vm.items, function (item) {
      return _c(
        "li",
        { key: item.name },
        [
          _c("a", { attrs: { href: item.uri } }, [_vm._v(_vm._s(item.label))]),
          _vm._v(" "),
          item.items && item.items.length
            ? _c("laptop-sub-menu-items", {
                attrs: { items: item.items, level: item.level },
              })
            : _vm._e(),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }