import workspace, { name as workspaceName } from '../../core/workspace';
import { createApp } from './app';

const { app } = createApp({
  locale: workspace.$locale,
  urls: {
    search_api: workspace.$urls.search_as_json,
    search: workspace.$urls.search,
  },
});

global[workspaceName].$search = app.$mount();
