import workspace, { name as workspaceName } from './workspace';
import * as accountMenu from './accountMenu';
import * as addToCart from './addToCart';
import * as cartSummary from './cartSummary';
import * as cookieBanner from './cookieBanner';
import * as mainMenu from './mainMenu';
import * as notification from './notification';
import * as productBox from './productBox';
import * as search from './search';
import * as stickyBar from './stickyBar';
import * as newsletterBanner from './newsletterBanner';

global[workspaceName] = workspace;

document.addEventListener('DOMContentLoaded', () => {
    accountMenu.prepareWorkspace();
    addToCart.prepareWorkspace();
    cartSummary.prepareWorkspace();
    cookieBanner.prepareWorkspace();
    mainMenu.prepareWorkspace();
    notification.prepareWorkspace();
    search.prepareWorkspace();

    accountMenu.onDOMLoaded();
    addToCart.onDOMLoaded();
    cartSummary.onDOMLoaded();
    cookieBanner.onDOMLoaded();
    mainMenu.onDOMLoaded();
    productBox.onDOMLoaded();
    search.onDOMLoaded();
    stickyBar.onDOMLoaded();
    newsletterBanner.onDOMLoaded();
});
