var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.notification
        ? _c("notification", {
            ref: "notification",
            attrs: { notification: _vm.notification },
            on: {
              close: function ($event) {
                return _vm.clearNotification(_vm.notification)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }