import workspace from './workspace';
import Cookies from 'js-cookie';

const prepareWorkspace = () => {
  const cookieInformAndAsk = document.getElementById('cookie-inform-and-ask');
  const cookieModalBackButton = document.querySelector('#cookie-inform-and-ask .modal__back');

  if (cookieInformAndAsk === null || cookieModalBackButton === null) {
    return;
  }
  // Create the prepared modal with cookie consent message
  const modal = workspace.$modalFactory({
    stickyFooter: false,
    closeMethods: ['escape'],
  });

  modal.setContent(cookieInformAndAsk);

  workspace.$cookieModal = modal;

  // Handle the cookie modal go back button
  cookieModalBackButton.addEventListener('click', closeModal, false);

  function closeModal() {
    modal.close();
  }
};

const onDOMLoaded = () => {
  const cookieBanner = document.getElementById('cookie-banner');
  const cookieMoreButton = document.getElementById('cookie-more-button');
  const gaCancelButton = document.getElementById('ga-cancel-button');
  const gaConfirmButton = document.getElementById('ga-confirm-button');
  const GA_COOKIE_NAMES = ['__utma', '__utmb', '__utmc', '__utmz', '_ga', '_gat'];
  const GA4_PROPERTY = 'G-FLLH6J1EWZ';
  const GA_PROPERTY = 'UA-117331706-1';

  function startGoogleAnalytics() {
    (function (i, s, o, g, r, a, m) {
      i.GoogleAnalyticsObject = r; i[r] = i[r] || function () {
        (i[r].q = i[r].q || []).push(arguments);
      }, i[r].l = 1 * new Date(); a = s.createElement(o),
      m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m);
    }(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga'));

    ga('create', GA_PROPERTY, 'auto');
    ga('set', 'anonymizeIp', true);
    ga('send', 'pageview');

    window.dataLayer = window.dataLayer || [];

    function gtag() { dataLayer.push(arguments); }

    gtag('js', new Date());
    gtag('config', GA4_PROPERTY);
    gtag('consent', 'default', {
      ad_user_data: 'granted',
      ad_personalization: 'granted',
    });
  }

  function reject() {
    Cookies.set(`ga-disable-${GA_PROPERTY}`, true, { expires: 395 });
    Cookies.set(`ga-disable-${GA4_PROPERTY}`, true, { expires: 395 });
    Cookies.set('hasConsent', false, { expires: 395 });

    window[`ga-disable-${GA_PROPERTY}`] = true;
    window[`ga-disable-${GA4_PROPERTY}`] = true;
    GA_COOKIE_NAMES.forEach(cookieName => Cookies.remove(cookieName));
  }

  function onGaConfirmButtonClick(event) {
    event.preventDefault();

    Cookies.set('hasConsent', true, { expires: 365 });
    startGoogleAnalytics();

    workspace.$cookieModal.close();

    gaCancelButton.removeEventListener('click', onGaCancelButtonClick, false);
    gaConfirmButton.removeEventListener('click', onGaConfirmButtonClick, false);
  }

  function onGaCancelButtonClick(event) {
    event.preventDefault();

    reject();

    workspace.$cookieModal.close();

    gaCancelButton.removeEventListener('click', onGaCancelButtonClick, false);
    gaConfirmButton.removeEventListener('click', onGaConfirmButtonClick, false);
  }

  function onDocumentClick(event) {
    const target = event.target;

    if (target.id === 'cookie-banner'
            || target.parentNode.id === 'cookie-banner'
            || target.parentNode.parentNode.id === 'cookie-banner'
            || target.id === 'cookie-more-button') {
      return;
    }

    event.preventDefault();

    Cookies.set('hasConsent', true, { expires: 365 });
    startGoogleAnalytics();

    cookieBanner.className = cookieBanner.className.replace('active', '').trim();

    document.removeEventListener('click', onDocumentClick, false);
    cookieMoreButton.removeEventListener('click', onMoreButtonClick, false);
  }


  function onMoreButtonClick(event) {
    event.preventDefault();

    workspace.$cookieModal.open();

    cookieBanner.className = cookieBanner.className.replace('active', '').trim();

    gaCancelButton.addEventListener('click', onGaCancelButtonClick, false);
    gaConfirmButton.addEventListener('click', onGaConfirmButtonClick, false);

    document.removeEventListener('click', onDocumentClick, false);
    cookieMoreButton.removeEventListener('click', onMoreButtonClick, false);
  }

  function processCookieConsent() {
    const consentCookie = Cookies.get('hasConsent');
    const doNotTrack = navigator.doNotTrack || navigator.msDoNotTrack;
    if (doNotTrack === 'yes' || doNotTrack === '1' || consentCookie === false) {
      reject();
      return;
    }

    if (consentCookie === true) {
      startGoogleAnalytics();
      return;
    }

    if (doNotTrack === 'no' || doNotTrack === '0') {
      Cookies.set('hasConsent', true, { expires: 395 });
      startGoogleAnalytics();
      return;
    }

    if (cookieBanner !== undefined) {
      cookieBanner.classList.add('active');
    }

    if (cookieBanner !== undefined) {
      cookieMoreButton.addEventListener('click', onMoreButtonClick, false);
    }

    document.addEventListener('click', onDocumentClick, false);
  }

  processCookieConsent();
};

export {
  prepareWorkspace,
  onDOMLoaded,
};
