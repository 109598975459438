<template>
    <div>
        <a
            :href="item.uri"
            v-for="item in items"
            :key="item.name"
            class="menumain__item"
            :data-menu-item="item.name"
            :class="{ 'menumain__item--active': selected === item.name }"
            v-on:click="select($event, item)"
        >{{ item.label }}</a>
    </div>
</template>

<script>
import itemMixin from '../mixin/item';

export default {
    name: 'laptop-main-menu',
    props: {
        items: {
            type: Array,
            required: true,
        },
        selected: {
            type: String,
            required: false,
        },
    },
    mixins: [ itemMixin ],
    methods: {
        select: function (event, item) {
            this.$emit('selectMainItem', item.name);

            if (this.hasItems(item)) {
                event.preventDefault();
                console.log(item)
            }
        },
    }
}
</script>