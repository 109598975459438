// import 'sylius/bundle/ShopBundle/Resources/private/entry';
import './scss/app.scss';
import './js/homepage';
import './js/core/entry';
import './js/common/modal/index';
import './js/common/rest/client';
import './js/common/rest/simpleClient';
import './js/common/rest/menuClient';
import './js/vue/cart/notification/entry';
import './js/vue/cart/summary/entry';
import './js/vue/common/bus/Bus';
import './js/vue/menu/entry';
import './js/vue/notification/entry';
import './js/vue/search/entry';
