var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "menumobile" },
    _vm._l(_vm.items, function (item) {
      return _c("li", { key: item.name }, [
        _c(
          "a",
          {
            class: {
              menumobile__item: _vm.hasItems(item),
              childfree: !_vm.hasItems(item),
              active: _vm.selected === item.name,
            },
            attrs: { href: item.uri },
          },
          [
            _vm._v(_vm._s(item.label) + " "),
            _c("span", {
              on: {
                click: function ($event) {
                  return _vm.select($event, item)
                },
              },
            }),
          ]
        ),
        _vm._v(" "),
        _vm.isOpen(item)
          ? _c(
              "ul",
              { class: { active: _vm.selected === item.name } },
              _vm._l(item.items, function (subitem) {
                return _c("mobile-menu-item", {
                  key: subitem.name,
                  attrs: { item: subitem },
                })
              }),
              1
            )
          : _vm._e(),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }