import tingle from 'tingle.js';

const Modal = tingle.modal;

const ShopFactory = function (options) {
  const resolved = Object.assign({}, options);

  resolved.beforeOpen = () => {
    document.querySelector('.main').classList.add('tingle-content-wrapper');

    if (typeof options.beforeOpen === 'function') {
      options.beforeOpen();
    }
  };

  resolved.onClose = () => {
    document.querySelector('.main').classList.remove('tingle-content-wrapper');

    if (typeof options.onClose === 'function') {
      options.onClose();
    }
  };

  return new Modal(resolved);
};

export default Modal;

export { ShopFactory };
