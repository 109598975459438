import baseClientFactory, { extractDataFromResponse } from './client';

// Base client (useful for mocking)
const baseClient = baseClientFactory('/api/public/menu');

// Client
const client = {
  getMenu(version) {
    return baseClient
      .get(`/?${version}`)
      .then(extractDataFromResponse);
  },
};

export default client;
export { baseClient };
