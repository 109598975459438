var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "tingle-modal-box__content" }, [
      _c(
        "div",
        { staticClass: "modal modal--notification", class: _vm.modalType },
        [
          _c("div", { staticClass: "modal__header" }, [
            _c("div", { staticClass: "modal__title" }, [
              _vm._v(_vm._s(_vm.notification.title)),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal__main" }, [
            _c("p", [_vm._v(_vm._s(_vm.notification.message))]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal__button", on: { click: _vm.close } },
              [_vm._v("OK")]
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }