import workspace from './workspace';
import { createApp as createCartSummaryApp } from '../vue/search/app';

const prepareWorkspace = () => {
  // Create the prepared modal with the vue app inside
  const modal = workspace.$modalFactory({
    stickyFooter: false,
    closeMethods: ['escape'],
    cssClass: ['tingle-modal--search'],
  });

    // Create the vue app & mount it
  const searchApp = createCartSummaryApp({
    locale: workspace.$locale,
    urls: {
      search_api: workspace.$urls.search_as_json,
      search: workspace.$urls.search,
    },
    onUpdated() {
      modal.checkOverflow();
    },
  }).app;

    // Mount app
  searchApp.$mount();

  modal.setContent(searchApp.$el);

  workspace.$searchModal = modal;

  // Handle the main menu go back button
  workspace.$bus.$on('searchGoBack', () => {
    modal.close();
  });
};

const onDOMLoaded = () => {
  document.querySelector('.topbar__searchbutton').addEventListener('click', (event) => {
    event.preventDefault();

    workspace.$searchModal.open();
  });
};

export {
  prepareWorkspace,
  onDOMLoaded,
};
