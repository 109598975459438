import { render, staticRenderFns } from "./AddToCartSuccess.vue?vue&type=template&id=4da5dc12"
import script from "./AddToCartSuccess.vue?vue&type=script&lang=js"
export * from "./AddToCartSuccess.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/apps/sylius/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4da5dc12')) {
      api.createRecord('4da5dc12', component.options)
    } else {
      api.reload('4da5dc12', component.options)
    }
    module.hot.accept("./AddToCartSuccess.vue?vue&type=template&id=4da5dc12", function () {
      api.rerender('4da5dc12', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets/shop/js/vue/cart/notification/components/AddToCartSuccess.vue"
export default component.exports