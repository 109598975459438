const onDOMLoaded = () => {
  // Get stickybar
  const stickyBar = document.getElementById('topbar');
  // Get header
  const header = document.getElementById('header');

  // Scroll detection
  window.onscroll = function scrollEvent() {
    if (document.documentElement.scrollTop > header.offsetTop) {
      stickyBar.classList.add('topbar--sticky');
    } else {
      stickyBar.classList.remove('topbar--sticky');
    }
  };
};

export { onDOMLoaded };
