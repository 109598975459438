<template>
  <div>
    <div v-if="products.length > 0">
      <h3 class="cart__subtitle">Produits ({{ products.length }})</h3>
      <div class="cartitem cartitem--heading">
        <div class="cartitem__name">Produit</div>
        <div class="cartitem__price">Prix</div>
        <div class="cartitem__quantity">Quantité</div>
        <div class="cartitem__amount">Total</div>
      </div>

      <item v-for="product in products" :key="product.name" :item="product"></item>
    </div>
    <div v-if="samples.length > 0 || products.length > 0">
      <h3 class="cart__subtitle">Echantillons ({{ samples.length }})</h3>
      <div v-if="products.length > 0">
        <p>Vous pouvez choisir vos 3 échantillons offerts en retournant dans la boutique et sélectionnant l’ajout d’un échantillon dans le produit que vous souhaitez.<br> Si vous préférez les surprises, nous effectuerons nous mêmes une sélection pour vous.</p>
        <p>Vous pouvez également acheter au maximum 5 échantillons supplémentaires</p>
      </div>
      <div v-else><p>Vous pouvez acheter jusqu'à 5 échantillons, et nous vous offrons en plus 3 échantillons si vous commandez un de nos produit</p></div>
      <div v-if="samples.length > 0">
        <div class="cartitem cartitem--heading">
          <div class="cartitem__name">Echantillon</div>
          <div class="cartitem__price">Prix</div>
          <div class="cartitem__quantity">Quantité</div>
          <div class="cartitem__amount">Total</div>
        </div>

        <item v-for="sample in samples" :key="sample.name" :item="sample"></item>
      </div>
    </div>
  </div>
</template>

<script>
import Item from './Item.vue';

export default {
  name: 'items',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  components: {
    Item,
  },
  computed: {
    products() {
      return this.items.filter(item => !item.is_sample);
    },
    samples() {
      return this.items.filter(item => item.is_sample);
    },
  }
};
</script>