<template>
    <div class="modal modal--search">
        <div class="modal__header">
            <div class="modal__title">Votre recherche</div>
            <div class="modal__back" v-on:click="goBack"><span>Retour</span></div>
        </div>
        <div class="modal__main">
            <div class="modal__searchform">
                <form class="searchform" v-on:submit.prevent="search">
                    <input type="text" @input="debounceInput" ref="search">
                    <button class="searchform__button"></button>
                </form>
            </div>

            <div class="modal__main" v-if="running && !pending && results.length === 0">
                <p>Aucun résultat pour votre recherche</p>
            </div>
            <div class="modal__products">
                <div
                    v-for="result in results"
                    v-html="result.html"
                    class="product product--list"
                    @mouseover="active = result.code"
                    @mouseout="active = false"
                    :class="{active: active === result.code}"
                ></div>
            </div>
        </div>
        <div class="modal__w-aside">
            <div class="modal__aside modal__aside--sticky">
                <a class="modal__searchbutton" v-if="total > 1" v-on:click.prevent="goToSearchPage">
                    Voir les&nbsp;<span>{{ total }}</span>&nbsp;produits
                </a>
                <a class="modal__button modal__button--back modal__button--ontop" v-on:click.prevent="goBack">Retour</a>
            </div>
        </div>
    </div>
</template>

<script>
import Bus from '../common/bus/Bus';
import $client from '../../common/rest/simpleClient';
import debounce from 'lodash.debounce';

export default {
    name: 'app',
    props: {
        locale: {
            type: String,
            required: true,
        },
        urls: {
            type: Object,
            required: true,
        },
        onUpdated: {
            type: Function,
            required: false,
        },
    },
    data: function () {
        return {
            // when a first search has been performed
            running: false,
            // search request is pending
            pending: false,
            // result is active (mouse hover)
            active: null,
            query: '',
            results: [],
            total: 0,
        };
    },
    methods: {
        debounceInput: debounce(function (event) {
            this.query = event.target.value;
            this.search();
        }, 400),
        goBack: function () {
            Bus.$emit('searchGoBack');
        },
        goToSearchPage: function () {
            window.location = `${this.urls.search}?search=${this.query}`;
        },
        search: function () {
            if (!this.query) {
                return;
            }

            this.running = true;
            this.pending = true;

            $client
                .get(`${this.urls.search_api}?search=${this.query}`)
                .then(data => {
                    this.results = data.results;
                    this.total = data.total;
                })
                .finally(() => {
                    this.pending = false;
                })
            ;
        },
    },
    updated: function () {
        if (this.onUpdated) {
            this.$nextTick(this.onUpdated);
        }
    },
};
</script>
