var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cartsummary" }, [
    _c("div", { staticClass: "cartsummary__item" }, [
      _c("div", { staticClass: "cartsummary__itemlabel" }, [
        _vm._v("Total produits TTC"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cartsummary__itemvalue" }, [
        _vm._v(_vm._s(_vm.totals.items_no_discount.formatted)),
      ]),
    ]),
    _vm._v(" "),
    _vm.totals.promotion_total.raw < 0
      ? _c("div", { staticClass: "cartsummary__item" }, [
          _c("div", { staticClass: "cartsummary__itemlabel" }, [
            _vm._v("Réduction"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "cartsummary__itemvalue" }, [
            _vm._v(_vm._s(_vm.totals.promotion_total.formatted)),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "cartsummary__item" }, [
      _c("div", { staticClass: "cartsummary__itemlabel" }, [
        _vm._v("Livraison"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cartsummary__itemvalue" }, [
        _vm._v(_vm._s(_vm.totals.shipping.formatted)),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "cartsummary__item" }, [
      _c("div", { staticClass: "cartsummary__itemlabel" }, [
        _vm._v("Total TVA"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cartsummary__itemvalue" }, [
        _vm._v(_vm._s(_vm.totals.tax.formatted)),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "cartsummary__item" }, [
      _c("div", { staticClass: "cartsummary__itemlabel" }, [
        _vm._v("Total TTC"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "cartsummary__itemvalue" }, [
        _vm._v(_vm._s(_vm.totals.total.formatted)),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }