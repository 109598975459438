var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.type === "success"
        ? _c("add-to-cart-success", {
            ref: "success",
            attrs: {
              cart: _vm.payload.cart,
              item: _vm.payload.item,
              urls: _vm.urls,
            },
            on: { goBack: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }