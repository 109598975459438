var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "modal modal--addtocart" }, [
      _c("div", { staticClass: "modal__header" }, [
        _c("div", { staticClass: "modal__title" }, [
          _vm._v("vous avez ajouté"),
        ]),
        _vm._v(" "),
        _c("a", { staticClass: "modal__back", on: { click: _vm.goBack } }, [
          _c("span", [_vm._v("Retour")]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal__main" }, [
        _c("div", { staticClass: "addtocart" }, [
          _c("div", { staticClass: "addtocart__image" }, [
            _vm.item.product.image
              ? _c("img", {
                  attrs: {
                    src: _vm.item.product.image.portrait_small,
                    alt: _vm.item.product.name,
                  },
                })
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "addtocart__content" }, [
            _c("div", { staticClass: "addtocart__productname" }, [
              _c("span", [_vm._v(_vm._s(_vm.item.product.name))]),
              _c("br"),
              _vm._v(" "),
              _vm.item.variant.options && _vm.item.variant.options.length
                ? _c("span", [
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.itemVariantOptions) +
                        "\n                        "
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "addtocart__quantity" }, [
              _vm._v("Quantité : "),
              _c("span", [_vm._v(_vm._s(_vm.item.quantity))]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "addtocart__price" }, [
              _vm._v("Prix : "),
              _c("span", [_vm._v(_vm._s(_vm.item.total.formatted))]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal__w-aside" }, [
        _c(
          "div",
          { staticClass: "modal__aside" },
          [
            _c("totals", { attrs: { totals: _vm.cart.totals } }),
            _vm._v(" "),
            _c(
              "a",
              { staticClass: "modal__button", attrs: { href: _vm.urls.cart } },
              [_vm._v("Commander")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "modal__button modal__button--back",
                on: { click: _vm.goBack },
              },
              [_vm._v("Continuer mon shopping")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }