<template>
    <div>
        <add-to-cart-success
            v-if="type === 'success'"
            ref="success"
            :cart="payload.cart"
            :item="payload.item"
            :urls="urls"
            v-on:goBack="closeModal"
        ></add-to-cart-success>
    </div>
</template>

<script>
import Bus from '../../common/bus/Bus';
import { ShopFactory as $modalFactory} from '../../../common/modal';
import AddToCartSuccess from './components/AddToCartSuccess.vue';

export default {
    name: 'app',
    props: {
        locale: {
            type: String,
            required: true,
        },
        urls: {
            type: Object,
            required: true,
        },
    },
    components: {
        AddToCartSuccess,
    },
    data: function () {
        return {
            type: null,
            payload: {},
        };
    },
    created () {
        this.$modal = new $modalFactory({});

        Bus.$on('cartItemAdded', (payload) => {
            this.type = 'success';
            this.payload = payload;

            this.$nextTick(() => {
                this.$modal.setContent(this.$refs.success.$el);

                if (!this.$modal.isOpen()) {
                    this.$modal.open();
                }
            });
        });
    },
    methods: {
        closeModal: function () {
            this.$modal.close();
        },
    },
};
</script>