<template>
    <div>
        <div class="tingle-modal-box__content">
            <div class="modal modal--notification" :class="modalType">
                <div class="modal__header">
                    <div class="modal__title">{{ notification.title }}</div>
                </div>

                <div class="modal__main">
                    <p>{{ notification.message }}</p>
                    <div class="modal__button" v-on:click="close">OK</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'notification',
    props: [
        'notification',
    ],
    computed: {
        modalType() {
            return this.notification.type === 'error' ? 'modal--error' : 'modal--success';
        }
    },
    methods: {
        close: function () {
            this.$emit('close');
        },
    },
};
</script>