var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cartitem" }, [
    _c("div", { staticClass: "cartitem__image" }, [
      _vm.item.product.image
        ? _c("img", {
            attrs: {
              src: _vm.item.product.image.square_thumbnail,
              alt: _vm.item.product.name,
            },
          })
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "cartitem__name" }, [
      _c("span", [_vm._v(_vm._s(_vm.item.product.name))]),
      _c("br"),
      _vm._v(" "),
      _vm.item.variant.options && _vm.item.variant.options.length
        ? _c("span", [
            _vm._v(
              "\n            " + _vm._s(_vm.itemVariantOptions) + "\n        "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "cartitem__price cartitem__price--responsive" },
        [
          _c("span", [_vm._v("Prix")]),
          _vm._v(" " + _vm._s(_vm.item.unit_price.formatted) + "\n        "),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "cartitem__quantity cartitem__quantity--responsive" },
        [
          _c("span", [_vm._v("Prix")]),
          _vm._v(" "),
          _vm.isDiscounted
            ? _c("div", { staticClass: "cartitem__originalprice" }, [
                _vm._v(_vm._s(_vm.item.unit_price.formatted)),
              ])
            : _vm._e(),
          _vm._v("\n          " + _vm._s(this.formattedPrice) + "\n        "),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "cartitem__amount cartitem__amount--responsive" },
        [
          _c("span", [_vm._v("Total")]),
          _vm._v(" " + _vm._s(_vm.item.total.formatted) + "\n        "),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "cartitem__price" }, [
      _vm.isDiscounted
        ? _c("div", { staticClass: "cartitem__originalprice" }, [
            _vm._v(_vm._s(_vm.item.unit_price.formatted)),
          ])
        : _vm._e(),
      _vm._v("\n      " + _vm._s(this.formattedPrice) + "\n    "),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "cartitem__quantity" }, [
      _c("span", [_vm._v("Quantité")]),
      _vm._v(" " + _vm._s(_vm.item.quantity) + "\n    "),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "cartitem__amount" }, [
      _vm._v("\n        " + _vm._s(_vm.item.total.formatted) + "\n    "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }