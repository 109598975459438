import workspace from './workspace';

const prepareWorkspace = () => {
  const accountMenuMobile = document.querySelector('.modal.modal--account');

  if (accountMenuMobile === null) {
    return;
  }
  // Handle the account menu mobile modal go back button
  const accountMenuModalBackButton = document.querySelector('.modal.modal--account .modal__back');

  // Create the prepared modal with account menu mobile content
  const modal = workspace.$modalFactory({
    stickyFooter: false,
    closeMethods: ['escape'],
  });

  modal.setContent(accountMenuMobile);

  accountMenuModalBackButton.addEventListener('click', closeModal, false);

  workspace.$accountMenuModal = modal;

  function closeModal() {
    modal.close();
  }
};

const onDOMLoaded = () => {
  const accountMenuMobile = document.querySelector('.modal.modal--account');

  if (accountMenuMobile === null) {
    return;
  }

  const smediumDownQuery = window.matchMedia('(max-width: 960px)');
  const accountButton = document.querySelector('.topbar__accountbutton');

  function handleAccountButtonClick(event) {
    event.preventDefault();

    workspace.$accountMenuModal.open();
  }

  function handleMatchMediaChange(query) {
    if (query.matches) {
      accountButton.addEventListener('click', handleAccountButtonClick);
    } else {
      accountButton.removeEventListener('click', handleAccountButtonClick);
    }
  }

  handleMatchMediaChange(smediumDownQuery);

  smediumDownQuery.addListener(handleMatchMediaChange);
};

export {
  prepareWorkspace,
  onDOMLoaded,
};
