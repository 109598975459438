<template>
    <div class="modal modal--cartsummary">
        <div class="modal__header">
            <div class="modal__title">Votre panier</div>
            <div class="modal__back" v-on:click="goBack"><span>Retour</span></div>
        </div>
        <div class="modal__main" v-if="!pending && cart.items.length == 0">
            <p>
                Votre panier est vide
            </p>
        </div>

        <div class="modal__main" v-if="!pending && cart.items.length">
            <items :items="cart.items"></items>
        </div>

        <div class="modal__w-aside" v-if="!pending && cart.items.length">
            <div class="modal__aside modal__aside--sticky">
                <div class="modal__inneraside">
                    <totals :totals="cart.totals"></totals>
                    <a :href="urls.cart" class="modal__button">Commander</a>
                    <a :href="urls.cart" class="modal__button modal__button--back">Modifier mon panier</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Bus from '../../common/bus/Bus';
import Items from './components/Items.vue';
import Totals from './components/Totals.vue';

export default {
    name: 'app',
    props: {
        locale: {
            type: String,
            required: true,
        },
        urls: {
            type: Object,
            required: true,
        },
    },
    data: function () {
        return {
            pending: false,
            cart: {
                items: [],
                totals: {},
            },
        };
    },
    components: {
        Items,
        Totals,
    },
    created () {
        Bus.$on('cartRefreshed', (cart) => {
            this.cart = cart;
            this.pending = false;
        });
        Bus.$on('cartRefreshing', (pending) => {
            this.pending = pending;
        });
    },
    methods: {
        goBack: function () {
            Bus.$emit('cartSummaryGoBack');
        },
    },
};
</script>