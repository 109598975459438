var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.products.length > 0
      ? _c(
          "div",
          [
            _c("h3", { staticClass: "cart__subtitle" }, [
              _vm._v("Produits (" + _vm._s(_vm.products.length) + ")"),
            ]),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _vm._l(_vm.products, function (product) {
              return _c("item", { key: product.name, attrs: { item: product } })
            }),
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.samples.length > 0 || _vm.products.length > 0
      ? _c("div", [
          _c("h3", { staticClass: "cart__subtitle" }, [
            _vm._v("Echantillons (" + _vm._s(_vm.samples.length) + ")"),
          ]),
          _vm._v(" "),
          _vm.products.length > 0
            ? _c("div", [
                _vm._m(1),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Vous pouvez également acheter au maximum 5 échantillons supplémentaires"
                  ),
                ]),
              ])
            : _c("div", [
                _c("p", [
                  _vm._v(
                    "Vous pouvez acheter jusqu'à 5 échantillons, et nous vous offrons en plus 3 échantillons si vous commandez un de nos produit"
                  ),
                ]),
              ]),
          _vm._v(" "),
          _vm.samples.length > 0
            ? _c(
                "div",
                [
                  _vm._m(2),
                  _vm._v(" "),
                  _vm._l(_vm.samples, function (sample) {
                    return _c("item", {
                      key: sample.name,
                      attrs: { item: sample },
                    })
                  }),
                ],
                2
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cartitem cartitem--heading" }, [
      _c("div", { staticClass: "cartitem__name" }, [_vm._v("Produit")]),
      _vm._v(" "),
      _c("div", { staticClass: "cartitem__price" }, [_vm._v("Prix")]),
      _vm._v(" "),
      _c("div", { staticClass: "cartitem__quantity" }, [_vm._v("Quantité")]),
      _vm._v(" "),
      _c("div", { staticClass: "cartitem__amount" }, [_vm._v("Total")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _vm._v(
        "Vous pouvez choisir vos 3 échantillons offerts en retournant dans la boutique et sélectionnant l’ajout d’un échantillon dans le produit que vous souhaitez."
      ),
      _c("br"),
      _vm._v(
        " Si vous préférez les surprises, nous effectuerons nous mêmes une sélection pour vous."
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cartitem cartitem--heading" }, [
      _c("div", { staticClass: "cartitem__name" }, [_vm._v("Echantillon")]),
      _vm._v(" "),
      _c("div", { staticClass: "cartitem__price" }, [_vm._v("Prix")]),
      _vm._v(" "),
      _c("div", { staticClass: "cartitem__quantity" }, [_vm._v("Quantité")]),
      _vm._v(" "),
      _c("div", { staticClass: "cartitem__amount" }, [_vm._v("Total")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }