import axios from 'axios';
import workspace from './workspace';
import { createApp as createCartNotificationApp } from '../vue/cart/notification/app';

const onAddToCartListener = (event) => {
  const dataset = event.target.dataset;

  workspace.$bus.$emit('addToCart', {
    product: dataset.product,
    variant: dataset.variant,
  });
};

const addToCartHandler = (command) => {
  const request = {
    sylius_add_to_cart: {
      cartItem: {
        variant: command.variant,
        quantity: command.quantity || 1,
      },
      _token: workspace.$csrfs.add_to_cart,
    },
  };

  axios.post(`${workspace.$urls.add_to_cart}?productId=${command.product}`, request, {
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
  })
    .then((response) => {
      const data = response.data;

      workspace.$bus.$emit('cartItemAdded', {
        cart: data.cart,
        item: data.item,
      });
    })
    .catch((error) => {
      let message;

      try {
        message = error.response.data.errors.errors.join(', ');
      } catch (error) {}

      workspace.$bus.$emit('dispatchNotification', {
        title: 'Ce produit ne peut pas être ajouté à votre panier',
        message,
        type: 'error',
      });
    });
};

const prepareWorkspace = () => {
  // Create the vue app & mount it
  const notificationApp = createCartNotificationApp({
    locale: workspace.$locale,
    urls: {
      cart: workspace.$urls.cart_summary,
    },
  }).app;
  workspace.$cartNotification = notificationApp.$mount();
};

const onDOMLoaded = () => {
  const buttons = document.querySelectorAll('.add_to_cart_button');

  if (buttons.length) {
    Array.from(buttons).forEach((link) => {
      link.addEventListener('click', onAddToCartListener);
    });
  }

  workspace.$bus.$on('addToCart', addToCartHandler);

  workspace.$bus.$on('cartItemAdded', (response) => {
    document.querySelector('div#topbar .topbar__cartbuttoncount').textContent = response.cart.items_units_count.toString();
  });
};

export {
  prepareWorkspace,
  onDOMLoaded,
};
