import Cookies from 'js-cookie';

const onDOMLoaded = () => {
  const popin = document.querySelector('.popin');

  if (!popin) {
    return;
  }

  const popinCloseButton = popin.querySelector('#popin-close-button');
  const popinInputElement = popin.querySelector('.popin__form form input[type="email"]');
  const popinSubmitButton = popin.querySelector('.popin__form form button');
  const hiddenPopin = Cookies.get('hideNewsletterPopin');

  if (hiddenPopin === 'true') {
    return;
  }

  const hidePopin = () => {
    popin.classList.remove('popin--show');
    Cookies.set("hideNewsletterPopin", 'true', 90);
  };

  if (popin && (popinCloseButton || popinSubmitButton)) {
    popinCloseButton?.addEventListener('click', hidePopin);
    popinSubmitButton?.addEventListener('click', () => (popinInputElement.checkValidity() && hidePopin()));
  }

  if (popin && hiddenPopin !== 'true' ) {
    setTimeout(() => popin.classList.add('popin--show'), 3000);
  }
};

export {
  onDOMLoaded,
};
