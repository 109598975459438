var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    [
      _c(
        "a",
        {
          staticClass: "menumobile__item",
          class: { active: _vm.open },
          attrs: { href: _vm.item.uri },
        },
        [
          _vm._v(_vm._s(_vm.item.label) + " "),
          _vm.hasItems ? _c("span", { on: { click: _vm.toggle } }) : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide-fade" } }, [
        _vm.open && _vm.hasItems
          ? _c(
              "ul",
              { class: { active: _vm.open } },
              _vm._l(_vm.item.items, function (subitem) {
                return _c("mobile-menu-item", {
                  key: subitem.name,
                  attrs: { item: subitem },
                })
              }),
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }