<template>
    <div class="modal modal--menumobile">
        <div class="modal__header">
            <div class="modal__title">Vous naviguez</div>
            <div class="modal__back" v-on:click="goBack"><span>Retour</span></div>
        </div>

        <mq-layout mq="laptop" class="modal__main">
            <laptop-main-menu
                :items="items"
                :selected="selected"
                v-on:selectMainItem="selectMainItem"
                class="menumain menumain--modal"
            ></laptop-main-menu>
            <laptop-sub-menu v-if="subItems.length" :items="subItems" :item="selectedItem"></laptop-sub-menu>
        </mq-layout>

        <mq-layout mq="mobile" class="modal__main modal__main--mobile">
            <mobile-menu
                :items="items"
                :selected="selected"
                v-on:selectMainItem="selectMainItem"
            ></mobile-menu>
        </mq-layout>
    </div>
</template>

<script>
import menuClient from '../../common/rest/menuClient';
import Bus from '../common/bus/Bus';
import LaptopMainMenu from './components/LaptopMainMenu.vue';
import LaptopSubMenu from './components/LaptopSubMenu.vue';
import MobileMenu from './components/MobileMenu.vue';

/**
 * @param component
 */
const initializeMenuData = (component) => {
    menuClient
        .getMenu(component.version)
        .then(menu => {
            component.$set(component, 'menu', menu);
        })
    ;
};

/**
 * @param component
 */
const initializeBus = (component) => {
    Bus.$on('selectMainMenuItem', (item) => {
        component.selectMainItem(item);
    });
};

export default {
    name: 'app',
    data: function () {
        return {
            menu: {},
            selected: "",
        };
    },
    props: {
        version: {
            type: String,
            required: true,
        },
    },
    components: {
        MobileMenu,
        LaptopMainMenu,
        LaptopSubMenu,
    },
    methods: {
        selectMainItem: function (item) {
            this.$set(this, 'selected', item);
        },
        goBack: function () {
            Bus.$emit('mainMenuGoBack');
        },
    },
    created () {
        initializeMenuData(this);
        initializeBus(this);
    },
    updated: function () {
        this.$nextTick(function () {
            Bus.$emit('mainMenuDomUpdated');
        })
    },
    computed: {
        items () {
            let menu = this.menu;
            return Object.keys(menu).map(key => menu[key]);
        },
        subItems () {
            return (this.selected && this.menu[this.selected]['items']) ? this.menu[this.selected]['items'] : [];
        },
        selectedItem () {
            return this.selected && this.menu[this.selected];
        },
    },
};
</script>