const onDOMLoaded = () => {
  // Toggle class on product box hover
  const products = document.getElementsByClassName('product product--list');

  [].forEach.call(products, (product) => {
    product.addEventListener('mouseleave', function () {
      this.classList.remove('active');
    });
    product.addEventListener('mouseenter', function () {
      this.classList.add('active');
    });
  });

  // Display product
  const smediumDownQuery = window.matchMedia('(max-width: 960px)');

  const productImages = document.querySelectorAll('.product--list .product__image');

  const productImagesArray = Array.from(productImages);

  function handleImageClick(event) {
    window.location = this.dataset.url;
  }

  function handleMatchMediaChange(query) {
    if (query.matches) {
      productImagesArray.forEach((image) => {
        image.addEventListener('click', handleImageClick);
      });
    } else {
      productImagesArray.forEach((image) => {
        image.removeEventListener('click', handleImageClick);
      });
    }
  }

  handleMatchMediaChange(smediumDownQuery);

  smediumDownQuery.addListener(handleMatchMediaChange);
};

export { onDOMLoaded };
