<template>
    <li>
        <a
            :href="item.uri"
            class="menumobile__item"
            :class="{ 'active': open }"
        >{{ item.label }} <span v-if="hasItems" v-on:click="toggle"></span></a>

        <transition name="slide-fade">
        <ul v-if="open && hasItems" :class="{ 'active': open }">
            <mobile-menu-item v-for="subitem in item.items" :key="subitem.name" :item="subitem"></mobile-menu-item>
        </ul>
        </transition>
    </li>
</template>

<script>

export default {
    name: 'mobile-menu-item',
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    data: function () {
        return {
            open: false
        }
    },
    methods: {
        toggle: function (event) {
            if (this.hasItems) {
                event.preventDefault();
                this.open = !this.open;
            }
        },
    },
    computed: {
        hasItems: function () {
            return this.item.items && this.item.items.length;
        }
    },
}
</script>

<style>
    .slide-fade-enter-active {
        transition: all .3s ease;
    }
    .slide-fade-leave-active {
        transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-enter, .slide-fade-leave-to {
        transform: translateX(10px);
        opacity: 0;
    }
</style>