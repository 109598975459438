import $modal, { ShopFactory as ShopModalFactory } from '../common/modal';
import $bus from '../vue/common/bus/Bus';

const name = '__ST__';
const workspace = global[name] || {};

workspace.$bus = $bus;
workspace.$modal = $modal;
workspace.$modalFactory = ShopModalFactory;
workspace.$mainmenu = null;
workspace.$cartsummary = null;
workspace.$notification = null;

export default workspace;
export { name };
