var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "submenu" }, [
    _c(
      "ul",
      { class: _vm.className },
      _vm._l(_vm.items, function (item) {
        return _c(
          "li",
          { key: item.name },
          [
            _c("a", { attrs: { href: item.uri } }, [
              _vm._v(_vm._s(item.label)),
            ]),
            _vm._v(" "),
            item.items && item.items.length
              ? _c("laptop-sub-menu-items", {
                  attrs: { items: item.items, level: item.level },
                })
              : _vm._e(),
          ],
          1
        )
      }),
      0
    ),
    _vm._v(" "),
    _vm.item.uri
      ? _c("div", { staticClass: "submenu__w-button" }, [
          _c(
            "a",
            { staticClass: "submenu__button", attrs: { href: _vm.item.uri } },
            [_vm._v('Voir tout "' + _vm._s(_vm.item.label) + '"')]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }