<template>
    <div class="cartitem">
        <div class="cartitem__image">
            <img v-if="item.product.image" :src="item.product.image.square_thumbnail" :alt="item.product.name">
        </div>
        <div class="cartitem__name">
            <span>{{ item.product.name }}</span><br>
            <span v-if="item.variant.options && item.variant.options.length">
                {{ itemVariantOptions }}
            </span>
            <div class="cartitem__price cartitem__price--responsive">
                <span>Prix</span> {{ item.unit_price.formatted }}
            </div>
            <div class="cartitem__quantity cartitem__quantity--responsive">
              <span>Prix</span>
              <div v-if="isDiscounted" class="cartitem__originalprice">{{ item.unit_price.formatted }}</div>
              {{ this.formattedPrice }}
            </div>
            <div class="cartitem__amount cartitem__amount--responsive">
                <span>Total</span> {{ item.total.formatted }}
            </div>
        </div>
        <div class="cartitem__price">
          <div v-if="isDiscounted" class="cartitem__originalprice">{{ item.unit_price.formatted }}</div>
          {{ this.formattedPrice }}
        </div>
        <div class="cartitem__quantity">
            <span>Quantité</span> {{ item.quantity }}
        </div>
        <div class="cartitem__amount">
            {{ item.total.formatted }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'item',
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    computed: {
        itemVariantOptions () {
            return this.item.variant.options.join(', ');
        },
        isDiscounted() {
            return this.item.discounted_unit_price.raw < this.item.unit_price.raw;
        },
        isFree() {
            return this.item.discounted_unit_price.raw === 0;
        },
        formattedPrice() {
            if (this.isFree) {
                return 'Offert';
            }

            if (this.isDiscounted) {
                return this.item.discounted_unit_price.formatted;
            }

            return this.item.unit_price.formatted;
        }
    },
};
</script>