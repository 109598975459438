import baseClientFactory, { extractDataFromResponse } from './client';

// Base client (useful for mocking)
const baseClient = baseClientFactory('/');

// Client
const client = {
  get(url) {
    return baseClient
      .get(url)
      .then(extractDataFromResponse);
  },
};

export default client;
export { baseClient };
