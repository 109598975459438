import Vue from 'vue';
import App from './App.vue';

export function createApp(options) {
  const app = new Vue({
    render(h) {
      return h(App, {
        props: {
          locale: options.locale,
          urls: options.urls,
          onUpdated: options.onUpdated,
        },
      });
    },
  });

  return { app };
}
