<template>
    <div class="submenu">
        <ul :class="className">
            <li v-for="item in items" :key="item.name">
                <a :href="item.uri">{{ item.label }}</a>
                <laptop-sub-menu-items
                    :items="item.items"
                    :level="item.level"
                    v-if="item.items && item.items.length"
                ></laptop-sub-menu-items>
            </li>
        </ul>
        <div class="submenu__w-button" v-if="item.uri">
            <a :href="item.uri" class="submenu__button">Voir tout "{{ item.label }}"</a>
        </div>
    </div>
</template>

<script>
import LaptopSubMenuItems from './LaptopSubMenuItems.vue';

export default {
    name: 'laptop-sub-menu',
    components: {
        LaptopSubMenuItems,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        item: {
            type: Object,
            required: true,
        },
    },
    computed: {
        className () {
            const level = this.item.name === 'all-brands' ? 2 : this.item.level;

            return `submenu__level${level}`;
        },
    },
}
</script>