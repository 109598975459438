var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal modal--search" }, [
    _c("div", { staticClass: "modal__header" }, [
      _c("div", { staticClass: "modal__title" }, [_vm._v("Votre recherche")]),
      _vm._v(" "),
      _c("div", { staticClass: "modal__back", on: { click: _vm.goBack } }, [
        _c("span", [_vm._v("Retour")]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal__main" }, [
      _c("div", { staticClass: "modal__searchform" }, [
        _c(
          "form",
          {
            staticClass: "searchform",
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.search.apply(null, arguments)
              },
            },
          },
          [
            _c("input", {
              ref: "search",
              attrs: { type: "text" },
              on: { input: _vm.debounceInput },
            }),
            _vm._v(" "),
            _c("button", { staticClass: "searchform__button" }),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.running && !_vm.pending && _vm.results.length === 0
        ? _c("div", { staticClass: "modal__main" }, [
            _c("p", [_vm._v("Aucun résultat pour votre recherche")]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal__products" },
        _vm._l(_vm.results, function (result) {
          return _c("div", {
            staticClass: "product product--list",
            class: { active: _vm.active === result.code },
            domProps: { innerHTML: _vm._s(result.html) },
            on: {
              mouseover: function ($event) {
                _vm.active = result.code
              },
              mouseout: function ($event) {
                _vm.active = false
              },
            },
          })
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "modal__w-aside" }, [
      _c("div", { staticClass: "modal__aside modal__aside--sticky" }, [
        _vm.total > 1
          ? _c(
              "a",
              {
                staticClass: "modal__searchbutton",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.goToSearchPage.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v("\n                Voir les "),
                _c("span", [_vm._v(_vm._s(_vm.total))]),
                _vm._v(" produits\n            "),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass:
              "modal__button modal__button--back modal__button--ontop",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.goBack.apply(null, arguments)
              },
            },
          },
          [_vm._v("Retour")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }